<template>
  <v-container>
    <v-card elevation="8" outlined>
      <v-card-title class="white--text grey darken-3"> Add Record </v-card-title>
      <v-card-text>
        <v-container class="mt-6">
          <v-row align="center" justify="center">
            <v-col class="text-center">
              <v-btn-toggle v-model="formSelection" mandatory color="grey darken-3" class="justify-center">
                <v-btn block> Fuel </v-btn>
                <v-btn block> Service </v-btn>
              </v-btn-toggle>
            </v-col>
          </v-row>
          <select-vehicle />
        </v-container>
      </v-card-text>
      <v-card-text v-show="loading">
        <v-skeleton-loader type="image"></v-skeleton-loader>
      </v-card-text>
      <v-tabs-items v-if="!loading && vehicle(currentVehicle)[0]" v-model="formSelection">
        <v-tab-item :key="0">
          <add-fuel-record></add-fuel-record>
        </v-tab-item>
        <v-tab-item :key="1">
          <add-service-record></add-service-record>
        </v-tab-item>
      </v-tabs-items>
    </v-card>
  </v-container>
</template>

<script>
import AddFuelRecord from '../../components/vehicleTracker/VehicleTrackerAddRecordFuel.vue'
import AddServiceRecord from '../../components/vehicleTracker/VehicleTrackerAddRecordService.vue'
import SelectVehicle from '../../components/vehicleTracker/VehicleTrackerLayoutSelectVehicle.vue'
import { mapGetters, mapActions, mapState } from 'vuex'

export default {
  name: 'VehicleTrackerAddRecord',
  components: {
    AddFuelRecord,
    AddServiceRecord,
    SelectVehicle
  },
  data() {
    return {
      formSelection: 0,
      selectedVehicle: null,
      loading: true
    }
  },
  computed: {
    ...mapState({
      vehicles: (state) => state.vehicle.vehicles,
      currentVehicle: (state) => state.vehicle.currentVehicle
    }),
    ...mapGetters('vehicle', ['vehicle'])
  },
  created() {
    this.getData()
  },
  methods: {
    ...mapActions('vehicle', ['fetchVehicles', 'updateCurrentVehicle']),
    async getData() {
      this.loading = true
      if (this.vehicles.length === 0) {
        await this.fetchVehicles()
      }
      if (localStorage.currentVehicle) {
        await this.updateCurrentVehicle(localStorage.currentVehicle)
        this.selectedVehicle = this.currentVehicle
      }
      this.loading = false
    },
    changeVehicleSelection(index) {
      const vehicle = this.vehicles[index].name
      this.updateCurrentVehicle(vehicle)
      localStorage.currentVehicle = vehicle
    }
  }
}
</script>

<style></style>
