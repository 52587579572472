<template>
  <v-container>
    <v-row justify="center">
      <v-col cols="12" sm="6">
        <v-card elevation="5">
          <v-form ref="addServiceRecord" id="addServiceRecord" name="addServiceRecord">
            <v-container>
              <div v-if="vehicleSelected">
                <v-row>
                  <v-col>
                    <v-menu
                      v-model="dateMenu"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="newEntry.date"
                          name="date"
                          label="Select date"
                          append-icon="mdi-calendar"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker v-model="newEntry.date" @input="dateMenu = false"></v-date-picker>
                    </v-menu>
                  </v-col>
                  <v-col>
                    <v-text-field
                      v-model="newEntry.mileage"
                      name="mileage"
                      label="Odometer"
                      type="number"
                      suffix="miles"
                      :placeholder="String(vehicle(this.currentVehicle)[0].current_mileage)"
                      persistent-hint
                      hint="ex: 123456"
                      :rules="[rules.required]"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-combobox
                      v-model="newEntry.service"
                      :items="serviceTypes"
                      name="service"
                      label="Service"
                      clearable
                      hide-selected
                      :rules="[rules.required]"
                    />
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-textarea v-model="newEntry.notes" name="notes" label="Notes" placeholder="Optional..."></v-textarea>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-text-field
                      v-model="newEntry.price"
                      name="price"
                      label="Price"
                      type="number"
                      persistent-hint
                      hint="ex: 1.23"
                      prefix="$"
                      @change="cleanPrice"
                      :rules="[rules.required]"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </div>
            </v-container>
            <v-container v-if="vehicleSelected">
              <v-row>
                <v-col cols="12" class="text-center">
                  <div class="font-weight-bold text-subtitle-1">New Service Log Record for {{ newEntry.vehicle }}</div>
                  <div class="text-subtitle-2">{{ newEntry.date }}</div>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="6" class="py-1 font-weight-bold">Service</v-col>
                <v-col cols="6" class="py-1 text-right">{{ summary.service }}</v-col>
                <v-col cols="6" class="py-1 font-weight-bold">Odometer</v-col>
                <v-col cols="6" class="py-1 text-right">{{ summary.mileage }}</v-col>
                <v-col cols="6" class="py-1 font-weight-bold">Cost</v-col>
                <v-col cols="6" class="py-1 text-right">{{ '$' + summary.price.toFixed(2) }}</v-col>
              </v-row>
            </v-container>
            <v-card-actions v-if="vehicleSelected">
              <v-btn color="red" text>clear</v-btn>
              <v-spacer></v-spacer>
              <v-btn :color="vehicle(this.currentVehicle)[0].color" :class="fontColor" @click="submit()">submit</v-btn>
            </v-card-actions>
          </v-form>
        </v-card>
      </v-col>
      <v-col cols="12" sm="6" v-if="vehicleSelected" class="d-none d-sm-flex">
        <vehicle-summary :vehicle="vehicle(this.currentVehicle)[0]"></vehicle-summary>
      </v-col>
    </v-row>
    <v-overlay :value="loading" absolute>
      <v-progress-circular indeterminate color="primary" />
    </v-overlay>
  </v-container>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex'
import VehicleSummary from '@/components/vehicleTracker/VehicleTrackerSummary.vue'

export default {
  name: 'AddServiceRecord',
  components: {
    VehicleSummary
  },
  data: () => ({
    newEntry: {
      date: new Date().toISOString().substr(0, 10),
      mileage: '',
      price: '',
      vehicle: '',
      service: '',
      notes: ''
    },
    dateMenu: false,
    loading: false
  }),
  computed: {
    ...mapState({
      vehicles: (state) => state.vehicle.vehicles,
      serviceTypes: (state) => state.vehicle.serviceTypes,
      currentVehicle: (state) => state.vehicle.currentVehicle
    }),
    ...mapGetters('vehicle', ['vehicle']),
    rules() {
      return {
        required: (value) => !!value || 'Required',
        greaterThanCurrent: (value) => value > this.vehicle(this.currentVehicle)[0].current_mileage || 'Check mileage'
      }
    },
    vehicleSelected() {
      return this.vehicle(this.currentVehicle).length > 0
    },
    fontColor() {
      const color = this.vehicle(this.currentVehicle)[0] ? this.vehicle(this.currentVehicle)[0].color.split(' ') : ''
      if (color.length > 1) {
        if (color[1].startsWith('dark')) {
          return 'white--text'
        } else {
          return 'black--text'
        }
      } else {
        return color
      }
    },
    summary() {
      var price = 0.0
      var service = 'New Service'
      var mileage = 0
      if (this.newEntry.service && this.newEntry.service.length > 0) {
        service = this.newEntry.service
      }
      if (Number(this.newEntry.mileage) > 0) {
        mileage = Number(this.newEntry.mileage)
      }
      if (Number(this.newEntry.price)) {
        price = Number(this.newEntry.price)
      }
      const result = {
        price,
        service,
        mileage
      }
      return result
    }
  },
  created() {
    this.getData()
  },
  mounted() {
    this.checkCurrentVehicle()
  },
  methods: {
    ...mapActions('vehicle', ['fetchVehicles', 'fetchServiceTypes', 'updateServiceLog']),
    getData() {
      if (this.vehicles.length === 0) {
        this.fetchVehicles()
      }
      if (this.serviceTypes.length === 0) {
        this.fetchServiceTypes()
      }
    },
    clearService() {
      this.newEntry.service = 'test'
    },
    cleanPrice() {
      var price = String(this.newEntry.price.replace(/\D/g, ''))
      let whole = price.slice(0, price.length - 2)
      let parts = price.substr(price.length - 2)
      this.newEntry.price = whole + '.' + parts
    },
    checkCurrentVehicle() {
      if (localStorage.currentVehicle) {
        this.newEntry.vehicle = localStorage.currentVehicle
      }
    },
    setCurrentVehicle(index) {
      localStorage.currentVehicle = this.vehicles[index].name
    },
    changeVehicleSelection(index) {
      this.newEntry.vehicle = []
      this.newEntry.vehicle = this.vehicles[index].name
      this.setCurrentVehicle(index)
    },
    clear() {
      this.newEntry = {
        date: new Date().toISOString().substr(0, 10),
        mileage: '',
        price: '',
        vehicle: '',
        service: '',
        notes: ''
      }
    },
    async submit() {
      if (this.$refs.addServiceRecord.validate()) {
        const data = {
          vehicle_name: String(this.vehicle(this.currentVehicle)[0].name),
          service: this.newEntry.service,
          date: this.newEntry.date,
          notes: this.newEntry.notes,
          mileage: Number(this.newEntry.mileage.replace(/\D/g, '')),
          price: Number(this.newEntry.price.replace(/\D/g, ''))
        }
        try {
          this.loading = true
          await this.updateServiceLog(data)
          this.clear()
          this.loading = false
          this.$refs.addServiceRecord.resetValidation()
        } catch (error) {
          this.loading = false
          this.$refs.addServiceRecord.resetValidation()
        }
      }
    }
  }
}
</script>

<style></style>
