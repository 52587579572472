<template>
  <v-container>
    <v-row justify="center">
      <v-col cols="12" sm="6">
        <v-card elevation="5">
          <v-form ref="addFuelRecord">
            <v-container>
              <div v-if="vehicleSelected">
                <v-row>
                  <v-col>
                    <v-menu
                      v-model="dateMenu"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="newEntry.date"
                          name="date"
                          label="Select date"
                          append-icon="mdi-calendar"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker v-model="newEntry.date" @input="dateMenu = false"></v-date-picker>
                    </v-menu>
                  </v-col>
                  <v-col>
                    <v-text-field
                      v-model="newEntry.mileage"
                      name="new_mileage"
                      label="Odometer"
                      type="number"
                      suffix="miles"
                      :placeholder="String(vehicle(this.currentVehicle)[0].current_mileage)"
                      :min="Number(vehicle(this.currentVehicle)[0].current_mileage)"
                      persistent-hint
                      hint="ex: 123456"
                      :rules="[rules.required, rules.greaterThanCurrent]"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-text-field
                      v-model="newEntry.price"
                      name="price"
                      label="Price"
                      type="number"
                      persistent-hint
                      hint="ex: 1.23"
                      prefix="$"
                      @change="cleanPrice"
                      :rules="[rules.required]"
                    ></v-text-field>
                  </v-col>
                  <v-col>
                    <v-text-field
                      v-model="newEntry.gallons"
                      name="gallons"
                      label="Gallons"
                      type="number"
                      persistent-hint
                      hint="ex: 12.345"
                      suffix="gals"
                      @change="cleanGallons"
                      :rules="[rules.required]"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </div>
            </v-container>
            <v-container v-if="vehicleSelected">
              <v-row>
                <v-col cols="12" class="text-center">
                  <div class="font-weight-bold text-subtitle-1">New Fuel Log Record for {{ newEntry.vehicle }}</div>
                  <div class="text-subtitle-2">{{ newEntry.date }}</div>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="6" class="py-1 font-weight-bold">Miles</v-col>
                <v-col cols="6" class="py-1 text-right">{{ transactionDetails.miles }}</v-col>
                <v-col cols="6" class="py-1 font-weight-bold">Miles per Gallon</v-col>
                <v-col cols="6" class="py-1 text-right">{{ transactionDetails.mpg }}</v-col>
                <v-col cols="6" class="py-1 font-weight-bold">Cost</v-col>
                <v-col cols="6" class="py-1 text-right">{{ '$' + transactionDetails.cost }}</v-col>
                <v-col cols="6" class="py-1 font-weight-bold">Cost per Mile</v-col>
                <v-col cols="6" class="py-1 text-right">{{ transactionDetails.cpm }}</v-col>
              </v-row>
            </v-container>
            <v-card-actions v-if="vehicleSelected">
              <v-btn color="red" text>clear</v-btn>
              <v-spacer></v-spacer>
              <v-btn :color="vehicle(this.currentVehicle)[0].color" :class="fontColor" @click="submit()">submit</v-btn>
            </v-card-actions>
          </v-form>
        </v-card>
      </v-col>
      <v-col cols="12" sm="6" v-if="vehicleSelected" class="d-none d-sm-flex">
        <vehicle-summary :vehicle="vehicle(this.currentVehicle)[0]"></vehicle-summary>
      </v-col>
    </v-row>
    <v-overlay :value="loading" absolute>
      <v-progress-circular indeterminate color="primary" />
    </v-overlay>
  </v-container>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex'
import VehicleSummary from '@/components/vehicleTracker/VehicleTrackerSummary.vue'

export default {
  name: 'AddFuelRecord',
  components: {
    VehicleSummary
  },
  data: () => ({
    newEntry: {
      date: new Date().toISOString().substr(0, 10),
      gallons: '',
      mileage: '',
      price: '',
      vehicle: ''
    },
    dateMenu: false,
    loading: false
  }),
  computed: {
    ...mapState({
      apiState: (state) => state.vehicle.apiState,
      vehicles: (state) => state.vehicle.vehicles,
      currentVehicle: (state) => state.vehicle.currentVehicle
    }),
    ...mapGetters('vehicle', ['vehicle']),

    rules() {
      return {
        required: (value) => !!value || 'Required',
        greaterThanCurrent: (value) => value > this.vehicle(this.currentVehicle)[0].current_mileage || 'Check mileage'
      }
    },
    transactionDetails() {
      var miles = 0
      var mpg = 0.0
      var cost = 0.0
      var cpm = 0.0
      if (Number(this.newEntry.mileage) > Number(this.vehicle(this.currentVehicle)[0].current_mileage)) {
        miles = Number(this.newEntry.mileage) - Number(this.vehicle(this.currentVehicle)[0].current_mileage)
      }
      if (Number(this.newEntry.gallons) > 0) {
        mpg = (Number(miles) / Number(this.newEntry.gallons)).toFixed(2)
      }
      if (Number(this.newEntry.price) > 0) {
        cost = ((Number(this.newEntry.price) + 0.009) * Number(this.newEntry.gallons)).toFixed(2)
      }
      if (cost > 0) {
        cpm = (Number(cost) / Number(miles)).toFixed(2)
      }
      const result = {
        miles,
        mpg,
        cost: String(Number(cost).toFixed(2)),
        cpm
      }
      return result
    },
    vehicleSelected() {
      return this.vehicle(this.currentVehicle).length > 0
    },
    fontColor() {
      const color = this.vehicle(this.currentVehicle)[0] ? this.vehicle(this.currentVehicle)[0].color.split(' ') : ''
      if (color.length > 1) {
        if (color[1].startsWith('dark')) {
          return 'white--text'
        } else {
          return 'black--text'
        }
      } else {
        return color
      }
    }
  },
  created() {
    this.getData()
  },
  mounted() {
    this.checkCurrentVehicle()
  },
  methods: {
    ...mapActions('vehicle', ['fetchVehicles', 'updateFuelLog']),
    async getData() {
      if (this.vehicles.length === 0) {
        this.fetchVehicles()
      }
    },
    checkCurrentVehicle() {
      if (localStorage.currentVehicle) {
        this.newEntry.vehicle = localStorage.currentVehicle
      }
    },
    setCurrentVehicle(index) {
      localStorage.currentVehicle = this.vehicles[index].name
    },
    changeVehicleSelection(index) {
      this.newEntry.vehicle = []
      this.newEntry.vehicle = this.vehicles[index].name
      this.setCurrentVehicle(index)
    },
    cleanPrice() {
      var price = String(this.newEntry.price.replace(/\D/g, ''))
      switch (price.length) {
        case 1:
          price = Number(price).toFixed(2)
          break
        case 2:
          price = (Number(price) / 10).toFixed(2)
          break

        default:
          price = price.length > 2 ? (Number(price.slice(0, 3)) / 100).toFixed(2) : Number(price).toFixed(2)
          break
      }
      this.newEntry.price = price
    },
    cleanGallons() {
      var gallons = String(this.newEntry.gallons.replace(/\D/g, ''))
      switch (gallons.length) {
        case 1:
          gallons = Number(gallons).toFixed(3)
          break
        case 2:
          gallons = (Number(gallons) / 10).toFixed(3)
          break
        case 3:
          gallons = (Number(gallons) / 100).toFixed(3)
          break

        default:
          gallons = gallons.length > 3 ? (Number(gallons.slice(0, 5)) / 1000).toFixed(3) : Number(gallons).toFixed(3)
          break
      }
      this.newEntry.gallons = gallons
    },
    clear() {
      this.newEntry = {
        date: new Date().toISOString().substr(0, 10),
        gallons: '',
        mileage: '',
        price: '',
        vehicle: ''
      }
    },
    async submit() {
      if (this.$refs.addFuelRecord.validate()) {
        const data = {
          vehicle_name: String(this.vehicle(this.currentVehicle)[0].name),
          date: this.newEntry.date,
          price: Number(this.newEntry.price.replace(/\D/g, '')),
          gallons: Number(this.newEntry.gallons.replace(/\D/g, '')),
          new_mileage: Number(this.newEntry.mileage.replace(/\D/g, '')),
          miles: this.transactionDetails.miles,
          mpg: Number(this.transactionDetails.mpg.replace(/\D/g, '')),
          cost: Number(this.transactionDetails.cost.replace(/\D/g, '')),
          cpm: Number(this.transactionDetails.cpm.replace(/\D/g, ''))
        }
        try {
          this.loading = true
          await this.updateFuelLog(data)
          this.clear()
          this.loading = false
          this.$refs.addFuelRecord.resetValidation()
        } catch (error) {
          this.loading = false
          this.$refs.addFuelRecord.resetValidation()
        }
      }
    }
  }
}
</script>

<style></style>
