<template>
  <v-row v-if="vehicles.length > 0" justify="center" align="center">
    <v-col sm="8" md="6" xl="4">
      <v-menu v-model="menu" close-on-content-click transition="slide-y-transition" offset-y bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-list>
            <v-list-item two-line v-bind="attrs" v-on="on">
              <v-list-item-avatar :class="vehicle(currentVehicle)[0].color">
                <v-icon large color="white">{{ vehicle(currentVehicle)[0].icon }}</v-icon>
              </v-list-item-avatar>

              <v-list-item-content>
                <v-list-item-title class="text-capitalize"> {{ vehicle(currentVehicle)[0].name }} </v-list-item-title>
                <v-list-item-subtitle class="text-capitalize">
                  {{ vehicle(currentVehicle)[0].year }} {{ vehicle(currentVehicle)[0].make }}
                  {{ vehicle(currentVehicle)[0].model }}
                </v-list-item-subtitle>
              </v-list-item-content>

              <v-list-item-action>
                <v-icon v-if="!menu" large>mdi-menu-down</v-icon>
                <v-icon v-if="menu" large>mdi-menu-up</v-icon>
              </v-list-item-action>
            </v-list-item>
          </v-list>
        </template>
        <v-list>
          <v-list-item two-line v-for="(vehicle, index) in vehicles" :key="index" @click="changeVehicleSelection(index)">
            <v-list-item-avatar :class="vehicle.color">
              <v-icon large color="white">{{ vehicle.icon }}</v-icon>
            </v-list-item-avatar>

            <v-list-item-content>
              <v-list-item-title class="text-capitalize"> {{ vehicle.name }} </v-list-item-title>
              <v-list-item-subtitle class="text-capitalize"> {{ vehicle.year }} {{ vehicle.make }} {{ vehicle.model }} </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-col>
  </v-row>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'

export default {
  name: 'VehicleTrackerLayoutSelectVehicle',
  data() {
    return {
      selectedVehicle: null,
      menu: false
    }
  },
  computed: {
    ...mapState({
      vehicles: (state) => state.vehicle.vehicles,
      currentVehicle: (state) => state.vehicle.currentVehicle
    }),
    ...mapGetters('vehicle', ['vehicle'])
  },
  created() {
    this.getData()
  },
  methods: {
    ...mapActions('vehicle', ['fetchVehicles', 'updateCurrentVehicle']),
    async getData() {
      if (this.vehicles.length === 0) {
        this.fetchVehicles()
      }
      if (localStorage.currentVehicle) {
        this.updateCurrentVehicle(localStorage.currentVehicle)
        this.selectedVehicle = this.currentVehicle
      }
    },
    changeVehicleSelection(index) {
      const vehicle = this.vehicles[index].name
      this.updateCurrentVehicle(vehicle)
      localStorage.currentVehicle = vehicle
    }
  }
}
</script>

<style></style>
