<template>
  <v-card tile fill-height>
    <v-toolbar flat :color="vehicleColor">
      <v-toolbar-title class="font-weight-bold">{{ vehicle.name }}</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn
        v-if="$route.name === 'VehicleTrackerList'"
        @click="navigateToVehicle(vehicle.name, 'VehicleTrackerLog')"
        text
        class="mx-3"
        :color="fontColor"
      >
        <span>Logs</span>
      </v-btn>
      <v-btn
        v-if="$route.name === 'VehicleTrackerList'"
        @click="navigateToVehicle(vehicle.name, 'VehicleTrackerAddRecord')"
        text
        class="mx-3"
        :color="fontColor"
      >
        <span>Records</span>
      </v-btn>
    </v-toolbar>

    <v-container>
      <v-row justify="space-between">
        <v-col cols="6" class="py-1 font-weight-bold"> Year: </v-col>
        <v-col cols="6" class="py-1 text-right">{{ vehicle.year }} </v-col>
        <v-col cols="6" class="py-1 font-weight-bold"> Make: </v-col>
        <v-col cols="6" class="py-1 text-right">{{ vehicle.make }} </v-col>
        <v-col cols="6" class="py-1 font-weight-bold"> Model: </v-col>
        <v-col cols="6" class="py-1 text-right">{{ vehicle.model }} </v-col>
        <v-col cols="6" class="py-1 font-weight-bold"> Odometer: </v-col>
        <v-col cols="6" class="py-1 text-right">{{ formatNumber(vehicle.current_mileage) }} </v-col>
        <v-col cols="6" class="py-1 font-weight-bold"> Average MPG: </v-col>
        <v-col cols="6" class="py-1 text-right">{{ formatNumber(vehicle.average_mpg / 100) }} </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>

<script>
import numberFormat from '@/shared/mixins/numberFormat'
import { mapActions } from 'vuex'
export default {
  name: 'VehicleSummary',
  props: {
    vehicle: { type: Object, required: true }
  },
  mixins: [numberFormat],
  computed: {
    fontColor() {
      const color = this.vehicle.color ? this.vehicle.color.split(' ') : ''
      if (color.length > 1) {
        if (color[1].startsWith('dark')) {
          return 'white'
        } else {
          return 'black'
        }
      } else {
        return 'black'
      }
    },
    vehicleColor() {
      const color = this.vehicle.color ? this.vehicle.color.split(' ') : ''
      if (color.length > 1) {
        if (color[1].startsWith('dark')) {
          return color[0] + ' ' + color[1] + ' white--text'
        }
        return color[0] + ' ' + color[1]
      } else {
        return color[0]
      }
    }
  },
  methods: {
    ...mapActions('vehicle', ['updateCurrentVehicle']),
    navigateToVehicle(name, route) {
      this.updateCurrentVehicle(name)
      localStorage.currentVehicle = name
      this.$router.push({ name: route })
    }
  }
}
</script>

<style></style>
