export default {
  methods: {
    formatNumber(num, decimals = null) {
      if (decimals) {
        return num
          .toFixed(decimals)
          .toString()
          .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
      } else {
        return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
      }
    },
    currencyFormat(num, decimals = 2) {
      return '$' + num.toFixed(decimals).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
    },
    arrayAverage(array) {
      if (array.length > 0) {
        return array.reduce((a, b) => a + b) / array.length
      } else {
        return 0
      }
    }
  }
}
